import dayjs from "dayjs"

import { NewMortgage } from "../../redux/services/consumerSide/PropertyApi.types"
import { ProductOption } from "../../redux/services/productSelection/ProductSelection.types"
import { Mortgage, RawMortgage } from "../../types/retain/Mortgages.types"
import { parseFloatOrNull } from "./parseFloatOrNull"

export function parseRawMortgages(rawMortgages: RawMortgage[]): Mortgage[] {
  let nullProperties = 0
  return rawMortgages.map((rawMortgage) => {
    const parsedProducts: ProductOption[] =
      rawMortgage.selectedProductOptions?.map((product) => ({
        ...product,
        aprc: parseFloat(product.aprc),
        fees: parseFloat(product.fees),
        followOnRate: parseFloat(product.followOnRate),
        ltv: parseFloat(product.ltv),
        monthlyFollowOnPayment: parseFloat(product.monthlyFollowOnPayment),
        monthlyPreferentialPayment: parseFloat(product.monthlyPreferentialPayment),
        preferentialRate: parseFloat(product.preferentialRate),
        ercYear1: parseFloatOrNull(product.ercYear1),
        ercYear2: parseFloatOrNull(product.ercYear2),
        ercYear3: parseFloatOrNull(product.ercYear3),
        ercYear4: parseFloatOrNull(product.ercYear4),
        ercYear5: parseFloatOrNull(product.ercYear5),
      })) ?? []
    const address = rawMortgage.prop.address || `Property ${(nullProperties += 1)}`
    return {
      ...rawMortgage,
      prop: {
        ...rawMortgage.prop,
        address,
      },
      expired: dayjs(rawMortgage.followOnStartDate).isBefore(dayjs()),
      selectedProductOptions: parsedProducts,
      consolidatedMortgages: [],
    }
  })
}

export function createRecordByMortgageId<T extends Mortgage | NewMortgage = Mortgage>(
  mortgages: T[]
): Record<string, T> {
  return mortgages.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: cur,
    }),
    {}
  )
}
