import { useAuth } from "../../../hooks/useAuth"

import { useGetMortgagesQuery } from "."

function useGetMortgagesQueryAuth() {
  const isAuth = useAuth()
  return useGetMortgagesQuery(undefined, { skip: !isAuth })
}

export default useGetMortgagesQueryAuth
