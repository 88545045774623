import { createApi } from "@reduxjs/toolkit/query/react"

import axiosBaseQuery from "./axiosBaseQuery"

export type EventType =
  | "blog-posts-viewed"
  | "choose-svr"
  | "concept-read"
  | "concept-understood"
  | "dashboard-viewed"
  | "financial-worry"
  | "flow-exited-early"
  | "flow-exited-successfully"
  | "flow-started"
  | "get-help"
  | "get-help-menu"
  | "learn-accept"
  | "learn-ignore"
  | "leave-svr"
  | "options-viewed"
  | "payments-viewed"
  | "priority-accept"
  | "priority-ignore"
  | "priority-property-viewed"
  | "property-viewed"
  | "return-home"
  | "review"
  | "see-options"
  | "view-property"

type ResponseType = {
  eventType: EventType
  propertyId?: string
  extraData?: unknown
}

export const eventApi = createApi({
  reducerPath: "eventApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  endpoints: (builder) => ({
    sendEvent: builder.mutation<null, ResponseType>({
      query: ({ eventType, propertyId, extraData }) => ({
        url: `/event/`,
        method: "POST",
        requestData: {
          eventType,
          propertyId,
          extraData,
        },
      }),
    }),
  }),
})

export const { useSendEventMutation } = eventApi
