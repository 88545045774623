import { createApi } from "@reduxjs/toolkit/query/react"

import axiosBaseQuery from "./axiosBaseQuery"
import { consumerTags } from "./consumerTags"

export const consumerApi = createApi({
  tagTypes: Object.values(consumerTags),
  reducerPath: "consumerApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  endpoints: () => ({}),
})
