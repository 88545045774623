import { consumerApi } from "../consumerApi"
import { consumerTags } from "../consumerTags"

export type ConversationType =
  | "svr-management"
  | "svr-compliance"
  | "svr-operational"
  | "eot-transactional"
  | "eot-educational"
  | null

export type Workflow =
  | "learn-more"
  | "understanding"
  | "eot-educational-intent"
  | "switching-intent"
  | "product-selection"
  | null

export type MortgageAlert = {
  type: ConversationType
  mortgageIds: string[]
}

export type Conversation = {
  type: ConversationType
  workflow: Workflow
  mortgageIds: string[]
  arguments: {
    understandingSubtopicId: string | null
    learningStarted: boolean
    learningFinished: boolean
    previousCta: string | null
  }
}

export type WhatNextProperty = {
  propertyId: string
  conversations: Conversation[]
}

export type WhatNextResponse = {
  count: number
  results: WhatNextProperty[]
}

/* TODO: ENG-8081 Use new conversations field in stop alerts and understanding complete responses */
type EndUnderstandingResponse = {
  arguments: {
    conversation: ConversationType
    understandingSubtopicId: string | null
    learningStarted: boolean
    learningFinished: boolean
    previousCta: string | null
  }
  workflow: Workflow
}

export const conversations = consumerApi.injectEndpoints({
  endpoints: (builder) => ({
    getWhatNext: builder.query<WhatNextResponse, void>({
      query: () => ({
        url: "/whats-next/",
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      providesTags: [consumerTags.whatsNext],
    }),
    postUnderstandingComplete: builder.mutation<
      EndUnderstandingResponse,
      {
        propertyId: string
      }
    >({
      query: ({ propertyId }) => ({
        url: `/property/${propertyId}/understanding-complete/`,
        method: "POST",
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      invalidatesTags: [consumerTags.whatsNext],
    }),
    postStopAlerts: builder.mutation<
      EndUnderstandingResponse,
      {
        propertyId: string
        choice: string
      }
    >({
      query: ({ propertyId, choice }) => ({
        url: `/property/${propertyId}/stop-alerts/`,
        method: "POST",
        ignoreStatuses: [401],
        requestData: {
          category: choice,
        },
      }),
      invalidatesTags: [consumerTags.whatsNext],
    }),
  }),
})

export const {
  useGetWhatNextQuery,
  usePostUnderstandingCompleteMutation,
  usePostStopAlertsMutation,
} = conversations
