import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"

import { lifecycleModule } from "../../components/consumer/Lifecycle/utils/lifecycleModules"
import { ProductRatesModal } from "../../components/consumer/Lifecycle/utils/productRatesModals"
import localStorageKeys from "../../constants/localStorageKeys"
import { getLocalStorageItem, getSessionStorageItem } from "../../lib/localStorageUtil"

export interface UserInterfaceState {
  isMenuShown: boolean
  isMenuExpanded: boolean
  isThanksModalShown: boolean
  isProductRatesModalShown: boolean
  isPropertySelectorOpen: boolean
  chosenProductRatesModal: ProductRatesModal
  isLifecycleModalShown: boolean
  chosenLifecycleModule: lifecycleModule
  isSpokeToClientModalShown: boolean
  isClientNoResponseModalShown: boolean
  chosenLifecycleClientId: string | null
  overrideMortgageIntroNeeded: boolean
  isPropertyGraphShown: string | null
  DEV_TOOL_showContentId: boolean
  DEV_TOOL_showContentHighlightButton: boolean
  DEV_TOOL_contentIsHighlighted: boolean
  firstRun?: boolean
  confetti: boolean
}

export const initialState: UserInterfaceState = {
  isMenuShown: false,
  isMenuExpanded: true,
  isThanksModalShown: false,
  isProductRatesModalShown: false,
  isPropertySelectorOpen: false,
  chosenProductRatesModal: ProductRatesModal.MONTHLY_COST_OF_SWITCHING,
  isLifecycleModalShown: false,
  chosenLifecycleModule: lifecycleModule.INTEREST_RATES,
  isSpokeToClientModalShown: false,
  isClientNoResponseModalShown: false,
  chosenLifecycleClientId: null,
  overrideMortgageIntroNeeded: true,
  isPropertyGraphShown: getSessionStorageItem(
    localStorageKeys.propertyValueGraphBeenShown
  ),
  DEV_TOOL_showContentId:
    getLocalStorageItem(localStorageKeys.showContentId) === "true",
  DEV_TOOL_showContentHighlightButton: false,
  DEV_TOOL_contentIsHighlighted: false,
  firstRun: true,
  confetti: false,
}

const userInterfaceSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    openMenu(state) {
      state.isMenuShown = true
    },
    closeMenu(state) {
      state.isMenuShown = false
    },
    toggleMenu(state) {
      state.isMenuShown = !state.isMenuShown
    },
    expandMenu(state) {
      state.isMenuExpanded = true
    },
    contractMenu(state) {
      state.isMenuExpanded = false
    },
    openThanksModal(state) {
      state.isThanksModalShown = true
    },
    closeThanksModal(state) {
      state.isThanksModalShown = false
    },
    openProductRatesModal(state, action: PayloadAction<ProductRatesModal>) {
      state.isProductRatesModalShown = true
      state.chosenProductRatesModal = action.payload
    },
    closeProductRatesModal(state) {
      state.isProductRatesModalShown = false
    },
    openLifecycleModal(state, action: PayloadAction<lifecycleModule>) {
      state.isLifecycleModalShown = true
      state.chosenLifecycleModule = action.payload
    },
    closeLifecycleModal(state) {
      state.isLifecycleModalShown = false
    },
    openPropertySelector(state) {
      state.isPropertySelectorOpen = true
    },
    closePropertySelector(state) {
      state.isPropertySelectorOpen = false
    },
    showPropertyGraph(state) {
      state.isPropertyGraphShown = dayjs().toISOString()
    },
    openSpokeToClientModal(state, action: PayloadAction<string>) {
      state.isSpokeToClientModalShown = true
      state.chosenLifecycleClientId = action.payload
    },
    closeSpokeToClientModal(state) {
      state.isSpokeToClientModalShown = false
      state.chosenLifecycleClientId = null
    },
    openClientNoResponseModal(state, action: PayloadAction<string>) {
      state.isClientNoResponseModalShown = true
      state.chosenLifecycleClientId = action.payload
    },
    closeClientNoResponseModal(state) {
      state.isClientNoResponseModalShown = false
      state.chosenLifecycleClientId = null
    },
    setDevToolShowContentId(state, action: PayloadAction<boolean>) {
      state.DEV_TOOL_showContentId = action.payload
    },
    toggleDevToolShowContentHighlightButton(state) {
      state.DEV_TOOL_showContentHighlightButton =
        !state.DEV_TOOL_showContentHighlightButton
    },
    toggleDevToolContentIsHighlighted(state) {
      state.DEV_TOOL_contentIsHighlighted = !state.DEV_TOOL_contentIsHighlighted
    },
    setOverrideMortgageIntroNeeded(state, action: PayloadAction<boolean>) {
      state.overrideMortgageIntroNeeded = action.payload
    },
    setFirstRun(state) {
      state.firstRun = false
    },
    fireConfetti(state) {
      state.confetti = true
    },
    resetConfetti(state) {
      state.confetti = false
    },
  },
})

export const {
  openMenu,
  closeMenu,
  toggleMenu,
  expandMenu,
  contractMenu,
  openThanksModal,
  closeThanksModal,
  openProductRatesModal,
  closeProductRatesModal,
  openPropertySelector,
  closePropertySelector,
  openLifecycleModal,
  closeLifecycleModal,
  showPropertyGraph,
  openSpokeToClientModal,
  closeSpokeToClientModal,
  openClientNoResponseModal,
  closeClientNoResponseModal,
  setDevToolShowContentId,
  toggleDevToolShowContentHighlightButton,
  toggleDevToolContentIsHighlighted,
  setOverrideMortgageIntroNeeded,
  setFirstRun,
  fireConfetti,
  resetConfetti,
} = userInterfaceSlice.actions
export default userInterfaceSlice.reducer
