export const lenderCTAPagesBlocks = {
  setReminderPage: {
    title: {
      type: "text",
      description: "Title of set reminder page",
      default: "Thanks, we'll send you an alert when new rates are available",
    },
    content: {
      type: "markdown",
      description: "Body content of set reminder page",
      default: `We typically offer new mortgage products 14 weeks before your current deal end.

We'll contact you then to tell you about your options and how to switch your deal if you want to.`,
    },
    image: {
      type: "image",
      description: "Image of set reminder page - default is a tick in a circle",
      default: null,
    },
  },
  noId: {
    title: {
      type: "text",
      description: "Title of no id page",
      default: "Please click through from an email link",
    },
    content: {
      type: "markdown",
      description: "Body content no id page",
      default: null, // its null because we set fallback on usage of id
    },
    image: {
      type: "image",
      description: "Image of no id page - default is a flag",
      default: null,
    },
  },
} as const
