import { LabelPaths } from "../../../hooks/blocks/util.types"

export const withSwitchSupportPageBlocks = {
  lender: {
    withSwitchSupport: {
      headerTitle: {
        type: "text",
        description: "Heading element for the /with-switch-support/ page",
        default: null,
      },
      heroImage: {
        type: "image",
        description:
          "Hero image for the /with-switch-support/ page. Fallback icon is a tick within a circle.",
        default: null,
      },
      introContent: {
        type: "markdown",
        description:
          "Copy displayed on the /with-switch-support/ page as the intro content.",
        default: null,
      },
      urlButton: {
        labelContent: {
          type: "text",
          description: "URL button label for  /with-switch-support/ page ",
          default: "Contact support",
        },
      },
      upperBodyContent: {
        type: "markdown",
        description:
          "Copy displayed on the /with-switch-support/ page as the upper body content.",
        default: null,
      },
      lowerBodyContent: {
        type: "markdown",
        description:
          "Copy displayed on the /with-switch-support/ page as the lower body content.",
        default: null,
      },
      codeLowerBodyContent: {
        type: "markdown",
        description:
          "Code displayed on the /with-switch-support/ page as the lower body content.",
        default: null,
      },
    },
  },
} as const

export type WithSwitchSupportLabelPaths = LabelPaths<typeof withSwitchSupportPageBlocks>
