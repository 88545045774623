import { createApi } from "@reduxjs/toolkit/query/react"

import { convertMortgagesIntoPropertiesList } from "../../../lib/convertMortgagesIntoPropertiesList"
import {
  createRecordByMortgageId,
  parseRawMortgages,
} from "../../../lib/parsers/parseMortgages"
import { Mortgage, Property, RawMortgage } from "../../../types/retain/Mortgages.types"
import axiosBaseQuery from "../axiosBaseQuery"

export type MortgageResponse = {
  results: RawMortgage[]
  defaultPropId: string | null
  count: number
}

export type MortgageResult = {
  count: number
  list: Record<string, Mortgage>
  properties: Property[]
  defaultPropId: string | null
}

export const MORTGAGES_TAG_TYPE = "Mortgages"

export const mortgagesApi = createApi({
  reducerPath: "mortgagesApi",
  baseQuery: axiosBaseQuery({
    baseUrl: "/api-v1",
  }),
  tagTypes: [MORTGAGES_TAG_TYPE],
  endpoints: (builder) => ({
    getMortgages: builder.query<MortgageResult, void>({
      query: () => ({
        url: `/mortgage/`,
        reportContext: {
          ignoreStatuses: [401],
        },
      }),
      providesTags: [MORTGAGES_TAG_TYPE],
      transformResponse({ results, count, defaultPropId }: MortgageResponse) {
        const mortgages = parseRawMortgages(results)
        const listedById = createRecordByMortgageId(mortgages)
        const properties = convertMortgagesIntoPropertiesList(mortgages)
        return {
          list: listedById,
          count,
          properties,
          defaultPropId,
        }
      },
    }),
  }),
})

export const { useGetMortgagesQuery } = mortgagesApi
