export const ButtonFill = {
  ACTION: "action",
  SOLID_ACTION: "solid-action",
  CONTRAST: "contrast",
  SECONDARY_CONTRAST: "secondary-contrast",
  WARNING: "warning",
  DANGER: "danger",
  PRIMARY: "primary",
  NONE: "transparent",
  CONFIRM: "confirm",
  CANCEL: "cancel",
} as const

export type ButtonFill = (typeof ButtonFill)[keyof typeof ButtonFill]

export const ButtonStyle = {
  NONE: "",
  OUTLINE: "outline",
  GHOST: "ghost",
  SOLID: "solid",
  ICON: "icon",
  BUTTON_AS_LINK: "button-as-link",
} as const

export type ButtonStyle = (typeof ButtonStyle)[keyof typeof ButtonStyle]

export type ButtonSize = "sizeDefault" | "sizeLarge"
