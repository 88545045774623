import dayjs from "dayjs"

import { ApprovalListConsumer } from "../components/business/LoadApproval/LoadApproval.types"
import { Suppression } from "../components/business/SuppressionList/Suppression.type"
import { lifecycleModule } from "../components/consumer/Lifecycle/utils/lifecycleModules"
import { EmployeeUser } from "../types/retain/Advisor.types"
import { Auth } from "../types/retain/Auth.types"
import { ArticleType, BlogPost, BlogPostFormat } from "../types/retain/BlogPost.types"
import {
  BareConsumer,
  Consumer,
  FakeConsumerTypes,
} from "../types/retain/Consumer.types"
import { currentSituationContent } from "../types/retain/CurrentSituation.types"
import { FakeEventTypes } from "../types/retain/Events.types"
import { File } from "../types/retain/Files.types"
import { Firm } from "../types/retain/Firms.types"
import { InvalidEmail } from "../types/retain/InvalidEmails.types"
import { Lists } from "../types/retain/List.types"
import {
  loanPurpose,
  Mortgage,
  mortgageTypes,
  paymentSchemes,
  paymentTypes,
  Property,
  RawMortgage,
} from "../types/retain/Mortgages.types"
import { getRandomName } from "./fakeRealNames"

const fakeName = (length = 0): string => Array(Math.floor(7 + length)).join("_")
const fakeWholeName = (): string => `${fakeName()} ${fakeName(4)}`

export const STUB_PERMISSION_GROUP = "stub perm"

export const fakeCurrentUserId = "6212c7de-dd6c-45be-8c7a-1f7f059860a4"
export const fakeIdentToken = "akcd2y-4cac10505636476320acd1cc7babe7f1"

export function fakeConsumers(
  n = 15,
  overrides: Partial<BareConsumer>[] = []
): BareConsumer[] {
  const fakeBaseConsumer: Omit<BareConsumer, "id" | "name"> = {
    state: null,
    advisor: null,
    hidden: false,
    isOrphan: false,
    lastActionedBy: null,
    isLocked: false,
    meetingBookedFrom: null,
    meetingBookedUntil: null,
    status: null,
    lastAction: null,
    mortgageHealthIntroNeeded: false,
  }

  return Array.from({ length: n }).map(
    (_, i) =>
      ({
        id: String(i),
        name: `${i}${fakeWholeName()}`,
        ...fakeBaseConsumer,
        ...overrides[i],
      }) as BareConsumer // TS doesn't seem to understand spreading...
  )
}

export function fakeEmployees(
  n = 15,
  overrides: Partial<EmployeeUser>[] = []
): EmployeeUser[] {
  const fakeBaseEmployee: Omit<
    EmployeeUser,
    "id" | "email" | "name" | "numAssignedLeads"
  > = {
    phoneNumber: "01234 567 890",
    type: "employee",
    bio: "",
    twoLetterInitials: "AB",
    isUser: false,
    isConsumer: false,
    isEmployee: true,
    isSupport: false,
    hidden: false,
    lastLogin: null,
    profilePicture: null,
    lists: [],
    permissionGroup: STUB_PERMISSION_GROUP,
  }

  return Array.from({ length: n }).map(
    (_, i) =>
      ({
        id: String(i),
        name: `${fakeWholeName()}${i}`,
        email: fakeName(),
        numAssignedLeads: i,
        ...fakeBaseEmployee,
        ...overrides[i],
      }) as EmployeeUser
  )
}

export function fakeSuppressionList(
  n = 15,
  overrides: Partial<Suppression>[] = []
): Suppression[] {
  const fakeBaseSuppression: Omit<
    Suppression,
    "email" | "name" | "unsubscribedUpdatedAt"
  > = {
    unsubscribedLists: [
      "Protection content",
      "Market update",
      "Product alerts",
      "Education content",
      "Events",
    ],
  }

  return Array.from({ length: n }).map(
    (_, i) =>
      ({
        ...fakeBaseSuppression,
        name: fakeWholeName(),
        email: `${i}${fakeName()}`,
        unsubscribedUpdatedAt: dayjs().subtract(i, "day").toISOString(),
        ...overrides[i],
      }) as Suppression
  )
}

export function fakeInvalidEmails(
  n = 15,
  overrides: Partial<InvalidEmail>[] = []
): InvalidEmail[] {
  const fakeBaseSuppression: Omit<InvalidEmail, "email" | "name" | "detectedAt"> = {
    reason: "invalid",
  }

  return Array.from({ length: n }).map(
    (_, i) =>
      ({
        name: fakeWholeName(),
        email: `${i}${fakeName()}`,
        detectedAt: dayjs().subtract(i, "day").toISOString(),
        ...fakeBaseSuppression,
        ...overrides[i],
      }) as InvalidEmail
  )
}

export function fakeFiles(n = 15, overrides: Partial<File>[] = []): File[] {
  const fakeBaseFile: Omit<File, "id" | "fileName" | "employee"> = {
    createdAt: "2020-06-11T14:38:20.795061+01:00",
    fileDescription: undefined,
    fileSize: 12345,
    user: null,
  }

  return Array.from({ length: n }).map(
    (_, i) =>
      ({
        id: `${i}`,
        fileName: fakeName(),
        employee: fakeEmployees(1)[0],
        ...fakeBaseFile,
        ...overrides[i],
      }) as File
  )
}

export const fakePermissions: Auth["permissions"] = {
  "firm-wide-view": false,
  "view-employee-activity": false,
  "employee-settings": false,
  "firm-settings": false,
  "management-reports": false,
  "latest-expiry-first": false,
  "view-suppression-list": false,
  "upload-files": false,
  "download-files": false,
  "assign-orphans": false,
  "can-be-assigned": false,
  "can-mark-as": true,
  "see-any-list": false,
  "see-search": false,
  "see-call-list": false,
  "see-lifecycle-list": false,
  "see-urgent-list": false,
  "see-portal-list": false,
  approval: false,
  "approval-internal": false,
  "show-all-leads-filter": false,
  "portal-can-mark-as": false,
  "comms-opted-out-mortgage": false,
  "view-invalid-email-list": false,
}

export const fakeRawMortgage: RawMortgage = {
  id: "b6a5fe3d-c8a2-4f16-9b14-542c003daa49",
  advisor: {
    id: "c444b776-847f-498d-bd9f-be1ac0d6df30",
    name: "Cameron Grant",
    twoLetterInitials: "CG",
    isUser: false,
    isConsumer: false,
    isEmployee: true,
  },
  description: "Cambridge & Counties's mortgage at 1.99 until 2022-11-25, SVR of 3.99",
  accountNumber: null,
  prop: {
    id: "6b2e824c-5787-413c-8d6c-2cc1acd7e4d1",
    address: "96 Stevenson groves\nChapmanside\nCV9 8FN",
    postcode: null,
    value: 310000,
    snoozed: false,
  },
  propertyValue: 294000,
  startDate: "2020-11-25",
  loanAmount: 220000,
  term: 300,
  currentRate: 1.99,
  preferentialRate: 1.99,
  expiryDate: "2022-11-25",
  followOnStartDate: "2022-11-26",
  followOnRate: 5.92,
  type: mortgageTypes.BUY_TO_LET,
  paymentType: paymentTypes.INTEREST_ONLY,
  scheme: paymentSchemes.NONE,
  loanPurpose: loanPurpose.PURCHASE,
  monthlyCurrentPayment: 1085.33,
  monthlyPreferentialPayment: 364.83,
  monthsOfPreferentialRate: 24,
  monthsUntilEndOfTermAfterPreferentialRate: 276,
  monthsUntilEndOfFullTerm: 279,
  monthlyFollowOnPayment: 1085.33,
  givenCurrentBalance: null,
  debtAfterPreferentialRate: 220000,
  loanToValueAfterPreferentialRate: 0.7482993197278912,
  lender: {
    id: "536d7ae5-57cb-4398-a173-1143eb95de2b",
    name: "Cambridge & Counties",
    logoBgColor: null,
    logoBgContrastColor: null,
    logo: null,
  },
  state: null,
  notInterestedCategory: null,
  isSharedOwnership: false,
  pctOwned: 100,
  caseId: null,
  caseLink: null,
  applicants: [
    {
      id: "11b95ba0-e168-41f8-a802-ab83e8f94b84",
      name: "Brian Wilson-Wright",
      receivesEmail: true,
    },
  ],
  pointOfContactId: "11b95ba0-e168-41f8-a802-ab83e8f94b84",
  pointOfContactIsExplicit: false,
  lifecycleModuleFlags: {
    "interest-rates": false,
    "expiry-date": true,
  },
}

export const fakeMortgage: Mortgage = {
  id: "bogusMortgage",
  accountNumber: "",
  advisor: {
    id: "bogusAdvisor",
    name: "Mr Advisor",
    isUser: false,
    isConsumer: false,
    isEmployee: true,
  },
  applicants: [],
  viewAsConsumerId: "2",
  pointOfContactId: "2",
  scheme: paymentSchemes.TRACKER,
  pctOwned: 1,
  emailEveryone: true,
  pointOfContactIsExplicit: true,
  state: undefined,
  caseId: undefined,
  caseLink: undefined,
  currentRate: 1.49,
  notInterestedCategory: undefined,
  givenCurrentBalance: undefined,
  debtAfterPreferentialRate: 76141.79,
  description: "Mortgages Ltd's best mortgage",
  expiryDate: "2017-04-08",
  followOnStartDate: "2017-04-09",
  expired: false,
  isSharedOwnership: false,
  lender: {
    id: "bogus-lender",
    name: "Mortgages Ltd",
    logo: undefined,
    logoBgColor: "#ffffff",
    logoBgContrastColor: "#704DFF",
  },
  loanAmount: 80600,
  loanToValueAfterPreferentialRate: 0.12,
  loanPurpose: loanPurpose.PURCHASE,
  monthlyCurrentPayment: 431,
  monthlyFollowOnPayment: 656,
  monthlyPreferentialPayment: 431,
  monthsOfPreferentialRate: 24,
  monthsUntilEndOfTermAfterPreferentialRate: 360 - 4,
  monthsUntilEndOfFullTerm: 360,
  paymentType: paymentTypes.INTEREST_ONLY,
  preferentialRate: 1.49,
  prop: {
    id: "bogusProp",
    address: "1 Canada Square\nLondon\nE14 5AB",
    value: 1000000.0,
    snoozed: false,
  },
  propertyValue: 125000,
  followOnRate: 4.99,
  startDate: "2015-07-08",
  term: 360,
  type: mortgageTypes.OWNER_OCCUPIED,
  selectedProductOptions: [],
  consolidatedMortgages: [],
}

export const fakeProperty: Property = {
  address: "09 Hawkins forks, Port Rhys, GU6P 6RG",
  id: "1ca34507-3cc0-4067-9f13-8747b9bf92ea",
  postcode: null,
  snoozed: false,
  value: 730000,
  mortgages: [fakeMortgage],
}

export const fakeCurrentUser: Consumer = {
  id: "user123",
  email: "email",
  title: "DR",
  name: "name",
  nickname: "name",
  phoneNumber: "1",
  type: ["consumer"],
  twoLetterInitials: "AB",
  isActive: false,
  isUser: false,
  isConsumer: true,
  isEmployee: false,
  canRequestCall: true,
  callRequestedAt: "2020-05-02T16:27:17.420264+01:00",
  unsubscribedGlobal: false,
  numTotalDocuments: 2,
  numCompletedDocuments: 1,
  hasCreatedAccount: true,
  permissionGroup: STUB_PERMISSION_GROUP,
  pages: {
    currentSituation: [
      currentSituationContent.CONTACT_US,
      currentSituationContent.SEE_RATES,
      currentSituationContent.MORE_OPTIONS,
      currentSituationContent.BOOK_APPOINTMENT,
    ],
  },
  lifecycleIntroNeeded: false,
  lifecycleModules: [
    lifecycleModule.INTEREST_RATES,
    lifecycleModule.PRODUCT_RATES,
    lifecycleModule.EXPIRY_DATE,
    lifecycleModule.PROPERTY_VALUE,
  ],
  mortgages: [],
}

export const fakeAdvisor: EmployeeUser = {
  email: "paul.mccartney@thebeatles.example.com",
  id: "13e30f3e-0353-4d98-943e-cbc57703bf34",
  isConsumer: false,
  isEmployee: true,
  isUser: false,
  isSupport: false,
  name: "Paul McCartney",
  bio: "",
  phoneNumber: "+44(0)151 496 0786",
  permissionGroup: STUB_PERMISSION_GROUP,
  twoLetterInitials: "PM",
  type: "employee",
  lists: [],
  numAssignedLeads: 0,
  profilePicture: null,
}

export const fakeFirm: Firm = {
  id: "user123",
  name: "name",
  legalName: "legalName",
  privacyPolicyUrl: "",
  termsOfBusinessUrl: "",
  accessibilityPolicyUrl: "",
  webDisclaimer: "",
  ownerOccupiedEmailDisclaimer: "",
  buyToLetEmailDisclaimer: "",
  squareLogo: "",
  squareLogo16: "",
  squareLogo24: "",
  squareLogo32: "",
  squareLogo57: "",
  squareLogo60: "",
  squareLogo64: "",
  squareLogo72: "",
  squareLogo76: "",
  squareLogo96: "",
  squareLogo114: "",
  squareLogo120: "",
  squareLogo144: "",
  squareLogo152: "",
  squareLogo160: "",
  actionColor: "",
  actionHoverColor: "",
  actionContrastColor: "",
  fullColorLogo: "",
  fullColorLogoCentred: "",
  whiteLogo: "",
  whiteLogoCentred: "",
  mainBackgroundColor: "",
  mainBoxBackgroundColor: "",
  mainTextColor: "",
  mainForegroundColor: "",
  mainBadColor: "",
  mainGoodColor: "",
  menuPulloutColor: "",
  menuPulloutContrastColor: "",
  primaryColor: "",
  primaryHoverColor: "",
  primaryContrastColor: "",
  mainWarningColor: "",
  primaryContrastHoverColor: "",
  secondaryColor: "",
  secondaryContrastColor: "",
  tertiaryColor: "",
  tertiaryContrastColor: "",
  buttonBorderRadius: "",
  featureFutureMockUps: false,
  featureMortgageHealth: false,
  featureDocumentChecklist: false,
  featureLegalSettings: false,
  showCrmSettings: true,
  portalUrl: "",
  hasUrgentList: false,
  isKba: false,
  isPortal: false,
  isTwoFactor: false,
  isSamlSsoEnabled: false,
  isSamlSsoReady: false,
  toolboxBranchId: "1234567",
  toolboxNetwork: "1234567",
  isLaunched: true,
  webChatUrl: "",
  referenceOnly: false,
  kbaConfiguration: undefined,
  readonlyLegalFields: [],
  crm: {
    id: "123",
    name: "MIDAS",
  },
  productSelectionExampleMath: false,
  isIdentTokenRequired: true,
  club: null,
  enableManualOfferMarker: false,
}

export const fakeLenderFirm: Firm = {
  id: "user123",
  name: "name",
  legalName: "legalName",
  privacyPolicyUrl: "",
  termsOfBusinessUrl: "",
  accessibilityPolicyUrl: "",
  webDisclaimer: "",
  ownerOccupiedEmailDisclaimer: "",
  buyToLetEmailDisclaimer: "",
  squareLogo: "",
  squareLogo16: "",
  squareLogo24: "",
  squareLogo32: "",
  squareLogo57: "",
  squareLogo60: "",
  squareLogo64: "",
  squareLogo72: "",
  squareLogo76: "",
  squareLogo96: "",
  squareLogo114: "",
  squareLogo120: "",
  squareLogo144: "",
  squareLogo152: "",
  squareLogo160: "",
  actionColor: "",
  actionHoverColor: "",
  actionContrastColor: "",
  fullColorLogo: "",
  fullColorLogoCentred: "",
  whiteLogo: "",
  whiteLogoCentred: "",
  mainBackgroundColor: "",
  mainBoxBackgroundColor: "",
  mainTextColor: "",
  mainForegroundColor: "",
  mainBadColor: "",
  mainGoodColor: "",
  menuPulloutColor: "",
  menuPulloutContrastColor: "",
  primaryColor: "",
  mainWarningColor: "",
  primaryHoverColor: "",
  primaryContrastColor: "",
  primaryContrastHoverColor: "",
  secondaryColor: "",
  secondaryContrastColor: "",
  tertiaryColor: "",
  tertiaryContrastColor: "",
  featureFutureMockUps: false,
  featureMortgageHealth: false,
  featureDocumentChecklist: false,
  featureLegalSettings: false,
  showCrmSettings: true,
  portalUrl: "",
  hasUrgentList: false,
  isKba: true,
  isPortal: true,
  isTwoFactor: false,
  isSamlSsoEnabled: false,
  isSamlSsoReady: false,
  toolboxBranchId: "1234567",
  toolboxNetwork: "1234567",
  kbaConfiguration: ["dob", "postcode", "surname"],
  referenceOnly: false,
  isLaunched: true,
  readonlyLegalFields: [],
  isIdentTokenRequired: true,
  club: null,
  enableManualOfferMarker: false,
}

export const fakeEvent: FakeEventTypes = {
  id: "39b097fc-a1a6-4a7d-8be5-1f7723dc0fa6",
  author: {
    id: "506ad803-8cd2-424c-846c-61fa18bd5940",
    name: "Paul McCartney",
    isUser: false,
    isConsumer: false,
    isEmployee: true,
  },
  type: "state-change--consumer",
  name: null,
  target: {
    id: "21750d80-a7f6-4223-9311-661e8cc1d54b",
    name: "Mark Burgess",
    isUser: false,
    isConsumer: true,
    isEmployee: false,
  },
  parentEvent: {
    newState: "booked",
    extraData: {
      newState: "booked",
      previousState: null,
      meetingBookedFrom: "2020-06-30T15:00:01+01:00",
      meetingBookedUntil: "2020-06-30T15:45:01+01:00",
      sentAt: "2020-06-05T14:58:10.842679+01:00",
    },
  },
  mortgageType: null,
  extraData: {
    newState: "booked",
    previousState: null,
    meetingBookedFrom: "2020-06-30T15:00:01+01:00",
    meetingBookedUntil: "2020-06-30T15:45:01+01:00",
    sentAt: "2020-06-05T14:58:10.842679+01:00",
    snoozedUntil: "2020-06-06T14:58:10.842679+01:00",
  },
  createdAt: "2020-06-05T14:58:10.842679+01:00",
  updatedAt: "2020-06-05T14:58:10.842746+01:00",
}

export const fakeConsumerResponse: FakeConsumerTypes = {
  id: "21750d80-a7f6-4223-9311-661e8cc1d54b",
  title: null,
  name: "Mark Burgess",
  callRequestedAt: null,
  emailSent: false,
  emailOpen: false,
  assigned: false,
  isOrphan: false,
  advisor: fakeAdvisor,
  state: null,
  hidden: false,
  remind: false,
  isLocked: true,
  lastActionedBy: null,
  meetingBookedFrom: "2020-06-30T15:00:01+01:00",
  meetingBookedUntil: "2020-06-30T15:45:01+01:00",
  isActive: true,
  mortgages: [
    {
      ...fakeMortgage,
      id: "09b19e88-8725-45cb-830d-b5ce4d4a6e4b",
    },
    {
      ...fakeMortgage,
      id: "8979b94a-6ea7-43af-aa79-6901f5425f09",
    },
    {
      ...fakeMortgage,
      id: "a06850aa-fed1-4523-9d99-d47a75b388d1",
      loanPurpose: loanPurpose.FURTHER_ADVANCE,
    },
  ],
  events: [fakeEvent],
  answers: [],
  communicable: true,
  lockedBy: fakeAdvisor,
  email: "mark.burgess@wood.info.example.com",
  phoneNumber: "08081570638",
  mortgageHealthIntroNeeded: false,
  lifecycleModules: [
    lifecycleModule.INTEREST_RATES,
    lifecycleModule.PRODUCT_RATES,
    lifecycleModule.EXPIRY_DATE,
    lifecycleModule.PROPERTY_VALUE,
  ],
}

export const fakeParsedConsumer = {
  ...fakeConsumerResponse,
  events: fakeConsumerResponse.events.map((event) => ({
    ...event,
    createdAt: new Date(event.createdAt),
    updatedAt: new Date(event.updatedAt),
    count: 1,
    extraData: {
      ...event.extraData,
      meetingBookedFrom: new Date(event.extraData.meetingBookedFrom),
      meetingBookedUntil: new Date(event.extraData.meetingBookedUntil),
      snoozedUntil: new Date(event.extraData.snoozedUntil || ""),
      sentAt: new Date(event.extraData.sentAt),
    },
    parentEvent: {
      ...event.parentEvent,
      extraData: {
        ...event.parentEvent.extraData,
        sentAt: new Date(event.parentEvent.extraData.sentAt),
      },
    },
  })),
  meetingBookedFrom: new Date(fakeConsumerResponse.meetingBookedFrom),
  meetingBookedUntil: new Date(fakeConsumerResponse.meetingBookedUntil),
  mortgages: [
    {
      ...fakeConsumerResponse.mortgages[0],
      startDate: new Date(fakeConsumerResponse.mortgages[0].startDate),
      expiryDate: new Date(fakeConsumerResponse.mortgages[0].expiryDate),
      expired: true,
    },
    {
      ...fakeConsumerResponse.mortgages[1],
      startDate: new Date(fakeConsumerResponse.mortgages[1].startDate),
      expiryDate: new Date(fakeConsumerResponse.mortgages[1].expiryDate),
      expired: true,
    },
  ],
  numMortgages: 3,
}

export const fakeListsWithAlerts: Lists = [
  { name: "all_leads", alert: null },
  { name: "meetings_booked", alert: 0 },
  { name: "my_leads", alert: 1 },
  { name: "call_list", alert: 2 },
  { name: "orphans", alert: 3 },
  { name: "urgent", alert: 4 },
]

export const fakePermissionGroups = [
  {
    id: "manager",
    name: "Manager",
    description: "Access to account settings + ability to view/action all leads",
  },
  {
    id: "orphan-allocator",
    name: "Orphan-allocator",
    description: "Ability to assign orphan leads",
  },
  {
    id: "advisor",
    name: "Advisor",
    description: "Ability to view/action assigned leads",
  },
  {
    id: "caller",
    name: "Caller",
    description: "Ability to view/action all leads",
  },
]

const fakeLender = {
  aliases: [],
  arithmeticMeanSvr: null,
  maxSvr: null,
  name: "1st Stop Home Loans",
  standardVariableRate: 5,
  unhandled: false,
}

export const fakeLenderResponse = {
  count: 3,
  next: null,
  previous: null,
  results: [
    fakeLender,
    { ...fakeLender, name: "2nd Stop Loans" },
    { ...fakeLender, name: "3rd Stop Loans" },
  ],
}

export const fakeCtaSettingsResponse = {
  showCtaPromptConsumer: true,
  showCtaPromptMobile: false,
  showCtaPromptTablet: false,
  showCtaPromptDesktop: true,
  ctaPromptText:
    "Did you know that you can request a call back and we’ll get back in touch with you within 48 hours?",
  ctaPromptDelay: 1,
}

export function fakeApprovalListConsumer(
  n = 35,
  overrides: Partial<ApprovalListConsumer>[] = []
): ApprovalListConsumer[] {
  const consumers = new Array<ApprovalListConsumer>()
  for (let i = 0; i < n; i++) {
    consumers.push({
      id: i.toString(),
      name: getRandomName(),
      crmId: `CRM${Math.floor(1000 + 9000 * Math.random())}`,
      hasClassification: Math.random() > 0.5,
      hasError: Math.random() > 0.5,
      hasReview: Math.random() > 0.5,
      advisorNames: [getRandomName(), getRandomName()],
      ...overrides,
    })
  }
  return consumers
}

export const portalContent = {
  login: {
    title: "Verify your ID",
    content: `
Enter your details below for up-to-date information about your mortgages and switching.
`,
  },
  navigation: {
    title: "Get started",
    content: `
Mortgage products typically start on a lower rate. At the end of your initial term, you move to our Follow-on Rate. This is usually higher than the rate your mortgage product started on.

You may be able to get a better rate by moving to a new product. You can switch directly with us or remortgage with another lender.

Switch today or learn more about the process of moving to a new product.
    `,
  },
  noId: {
    image:
      "https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/get-started.png",
    title: "Please click through from an email link",
    content: `
For extra security we require you to use one of the links in the email we sent you in order to access this page.

Alternatively, you can visit our product switch portal to start the switching process.

[Visit the product switch portal &rarr;](https://en.wikipedia.org/wiki/Main_Page)`,
  },
  switch: {
    content: `
You can move to a new mortgage product to avoid your Follow-on Rate.

View new rates available to you on our product switch portal.

Apply and switch today if you’re up to date with your payments and there has been no change to your circumstances.
    `,
  },
  help: {
    content: `
If you’re not sure if you can switch or want to borrow more, view your other options.

Remember: you can learn more about moving to a new product and what might be right for you by selecting "Explore Process" on the home screen.
`,
  },
  getStarted: {
    title: "Your options",
    content: `
Switch your product to get on the best deal for you. Use our product switch portal to switch today or contact a mortgage advisor for advice.
    `,
  },
  phoneNumber: {
    content: "0344 892 0155",
  },
  accountNumber: {
    title: "Your account number",
    content:
      "Click copy and proceed to our product portal to view rates, or to start the switching process.",
  },
  accountNumberConfirmation: {
    content: `You will be asked again to confirm:
- Your surname
- Your date of birth
- Your account number (listed above)
`,
  },
  ifCantSwitch: {
    title: "It’s too soon to action this mortgage",
    content: `
You may still be able to action one of your other mortgages, please choose one from the dropdown at the top of the page.
    `,
  },
  learn: {
    title: "Why do mortgage payments change?",
    content: `
Switching to a new mortgage product with us, or remortgaging with a new lender, is a big financial decision. Like your initial mortgage, you need to select the right product for you today.

It is common to move to a new mortgage product before the end of the initial term to avoid a higher payment. Moving to a new mortgage product may also allow additional borrowing or debt consolidation.

Understand in depth why mortgage payments change, the process for moving to a new mortgage product and how to make the right decision for you.
      `,
  },
  setReminderPage: {
    image:
      "https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/confirmTick.svg",
    title: "Thanks, we'll send you an alert when new rates are available",
    content: `We typically offer new mortgage products 14 weeks before your current deal
              end.
              We'll contact you then to tell you about your options and how to switch
              your deal if you want to.`,
  },
  alreadyActionedFormSubmit: {
    content: "Disable all alerts",
  },
  alreadyActionedFormSubmitLoading: {
    content: "Disabling alerts...",
  },
  stopAlertsMultipleMortgages: {
    content:
      "To stop email notifications, select the right properties and let us know how you're dealing with your mortgage.",
  },
  stopAlertsSingleMortgage: {
    content:
      "To stop email notifications, select the right property and let us know how you're dealing with your mortgage.",
  },
  updateNotificationsMortgageSelector: {
    content:
      "These alerts are related to factual information and notifications about products and services that we provide to you.",
  },
  stopAlerts: {
    title: "Stop alerts",
  },
  updateNotifications: {
    title: "Manage alerts",
  },
}

export const portalContentArticles: BlogPost[] = [
  {
    slug: "1",
    featuredImage:
      "https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/document@2x.png",
    title: "Do I need to switch my mortgage product?",
    readingTime: 3,
    id: "",
    featuredThumbnail: "",
    callToActionText: "",
    callToActionUrl: "",
    tagNames: [""],
    type: ArticleType.LENDER,
    format: BlogPostFormat.MARKDOWN,
    body: `
### What is the Follow-on Rate again?
The Follow-on Rate is the interest rate your product changes to at the end of the initial term (for example after 2 years, 3 years, 5 years, etc).
So on a 2-year fixed product, you would move on to the Follow-on Rate after 2-years.
The Follow-on Rate is also a variable rate which means it can go up or down over time. It is also typically higher than the introductory rate you were on during your initial product term.
Finally, each lender calls this type of rate something different; for example, some lenders refer to it as the Standard Variable Rate (SVR), but at Company Homeloans we call this Follow-on Rate our “Reversionary Rate”.
### How do I avoid the Follow-on Rate?
You can avoid going on to our Follow-on Rate by moving to a new product when your initial term ends. You can move to a new product by doing a product switch with us on our online product switch portal (subject to the eligibility criteria) or remortgaging with a different lender.
You can see the products available to you as early as 6 months before your initial terms ends on our portal.
If you are at the end of your initial term, or are already on a Follow-on Rate, you could still switch to a new mortgage product (depending on your circumstances).
### Do I really need to consider switching?
Anyone who has a mortgage where the initial term is coming to an end, should explore alternative products available to ensure the monthly repayment remains within their budget.
People often move to a new product when their fixed, introductory tracker or discounted rate period ends. This initial period is usually 2-5 years after the mortgage starts.  If you do not move to a new product immediately, you can do so later (depending on your circumstances).
You don’t have to move to a new product, but most people do to save money by moving to a lower rate.
        `,
  },
  {
    slug: "2",
    featuredImage:
      "https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/calendar@2x.png",
    title: "How soon can I get a new mortgage?",
    readingTime: 5,
    id: "",
    featuredThumbnail: "",
    callToActionText: "",
    callToActionUrl: "",
    tagNames: [""],
    format: BlogPostFormat.MARKDOWN,
    type: ArticleType.LENDER,
    body: `
As your mortgage approaches the end of its initial term you may choose to move to a new product (either with us or with another lender).
The process to move to a different lender (known as a remortgage) has five key stages and can take several weeks to complete. You can switch to a new product with us online via our product switch portal. A product switch with us does not need additional documentation, a valuation and the offer process can often happen on the same day.
See below for the stages required for a remortgage versus a product switch (with us), as well as how long a stage typically takes.
![Mortgage timeline](https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/timeline.png)
It’s also important that you factor in all the additional costs that remortgaging to another lender will incur.
To see your payments click "[View mortgage](/portal/your-mortgage-payments)" below.
_It’s important to note that a product switch is not an option when you are looking to borrow more money than your current mortgage amount. Learn more by reading “[What’s the difference between a product switch and a remortgage](/portal/learn/article/4)” or asking an independent financial advisor to talk you through your options._
### 1: Product selection
Your first step is to take a look at mortgage products available and determine which products best suit your needs. Clicking "[Switch now](/portal/switch-now)" at the bottom of this article will take you to our online product switch portal.
When you log on, we’ll instantly show you the products and rates we are able to offer you. You don’t have to proceed with a product switch to see products and rates.
If you want to see products and rates from other lenders an independent financial advisor can help.
### 2: Document collection
If you’re using a new lender they will require updated documentation – and each lender will have different requirements. Your independent financial advisor will be able to help you understand each lender’s specific requirements.
### 3: Property valuation
If you decide to borrow more or move to a new lender you may need a new property valuation. This valuation is undertaken to confirm a current market value of your property (this may or may not be at your cost).
### 4: Offer
The offer and ESIS (European Standard Information Sheet) is a formal document issued to you that details all aspects of the mortgage. Your advisor will take you through the document.
With our online portal, you will receive your Offer and ESIS instantly.
### 5: Completion
For a remortgage you will need a solicitor to help you complete the process. This includes conducting necessary property searches, repaying your mortgage with us and requesting funds from your new lender.
If you decide to switch with us, we don’t need additional information and you can formally accept the offer online.
Click "[View mortgage](/portal/your-mortgage-payments)" below to see your payments today and rates available.
_It’s important to note that a product switch is not an option when you are looking to borrow more money than your current mortgage amount. Learn more by reading “[What’s the difference between a product switch and a remortgage](/portal/learn/article/4)” or asking an independent financial advisor to talk you through your options._
        `,
  },
  {
    slug: "3",
    featuredImage:
      "https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/compass@2x.png",
    title: "How do I select a new mortgage product?",
    readingTime: 8,
    id: "",
    featuredThumbnail: "",
    callToActionText: "",
    callToActionUrl: "",
    tagNames: [""],
    format: BlogPostFormat.MARKDOWN,
    type: ArticleType.LENDER,
    body: `
To pick the right mortgage product you must weigh up your personal circumstances against different products and their features to arrive at a solution that best suits your needs. You can also speak to an independent financial advisor for advice.
### Where do I start?
The first thing to decide is whether you want to do a product switch or a remortgage.
A product switch is offered by us. We let you switch (online) to a new mortgage product, where there are no changes to the loan amount and the repayment term matches the remaining term.
If you want to change mortgage product features such as the loan amount or repayment term, you will have to remortgage (learn more by reading “[What’s the difference between a product switch and a remortgage?](/portal/learn/article/4)”).
It is important to fully understand the impact of your product’s features on your situation today and your future goals. Read below for a refresher on the key mortgage features you need to know.
### What’s the ‘initial term’?
Whether you decide to product switch with us or remortgage away to another lender, you will need to pick the initial term.
The initial term is a set number of years that you commit to on your mortgage. This means that during this period you can’t change your mortgage (for example switching to another product or repaying your mortgage because you are selling your home), without incurring a fee, known as the early redemption charge (ERC).
Term lengths are commonly 2, 3 or 5 years – though some can be longer. It is important to think carefully about the term length, as exiting a product before the initial term is up can be costly and ERCs can be calculated as a percentage of the outstanding loan balance, depending on what the ERC is for that product.
Remember, the initial term is different to the repayment term (which is the number of years you’re committing to repay the mortgage in full).
For a product switch, the repayment type and term for your new product will be linked to your existing mortgage with us.
For example, let’s say your current mortgage is:
1. a 5-year fixed term,
2. and the repayment term was 30 years.
Then at the end of the 5-year initial term, you could do a product switch to a mortgage with a 25-year repayment term (30 years minus 5 years).
### I have a fixed rate, what does this mean?
Mortgage products generally have two types of interest rates: fixed rate products and variable rate products.
Fixed rate deals, as the name suggests, let you ‘fix’ or lock-in an interest rate up front that doesn’t change during the initial term – typically 2 or 5 years (as we talked about above). The main advantage of a fixed rate mortgage is that you know your monthly repayments will not change during the initial term even if interest rates go up, giving you budget certainty.
By contrast, variable mortgages, often referred to as trackers or discount variable products, have an interest rate that can change over time. Typically, the interest rate is linked to an external interest rate (for example, the Bank of England rate).
As a result, with a variable rate you benefit if interest rates fall but face higher monthly repayments if they rise.
Remember, variable mortgages (trackers and discount variable) should not be confused with our Follow-on Rate, which is the rate you move to when your initial term ends.
The key factors to bear in mind when deciding whether to opt for a fixed or variable rate are whether you think interest rates will rise or fall during your mortgage term, and how much flexibility you have in your budget to accommodate higher mortgage repayments. It’s important to seek advice from an independent financial advisor if you are unsure as to which option to take.
### Is that it, or do I need to consider anything else?
Each lender and every product they offer is different so there are many options which you need to consider. If you decide to remortgage or do a product switch, you need to choose the product which is right for you.
Some helpful tips to remember:
1. There are a myriad of mortgage products out there, so you can seek advice from an independent financial advisor to help guide you in this process.
2. Whilst rate is important, you also need to understand all the other associated costs with a mortgage. For example, a product switch with us will not incur any valuation or conveyancing costs but you will be committing to an initial term. If you commit to an initial term and then want to move house or change products before the initial term is up, this might mean an early repayment charge may be incurred. You may also want to look at whether products allow you to make overpayments without incurring penalties, enabling you to pay down your mortgage quicker and so pay less interest overall.
Remember: When deciding whether to do a product switch or remortgage, it’s important to factor in all the benefits and costs of a product. If you’re not sure what you may need or your circumstances have changed, make sure you speak to an independent financial advisor.
`,
  },
  {
    slug: "4",
    featuredImage:
      "https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/options.png",
    title: "What’s the difference between a product switch and a remortgage?",
    readingTime: 2,
    id: "",
    featuredThumbnail: "",
    callToActionText: "",
    callToActionUrl: "",
    tagNames: [""],
    format: BlogPostFormat.MARKDOWN,
    type: ArticleType.LENDER,
    body: `
The initial term on your mortgage product with us is coming to an end. To avoid going on to our Follow-on Rate (typically higher than your initial rate and the rate you will automatically revert to if you do nothing), you will either need to switch to a new product with us, known as a product switch, or you can remortgage with a different lender.
### What’s a product switch?
A product switch is when you move from your existing mortgage product to a new one. This option is available when you come to the end of your initial term. A product switch means you move to a new interest rate, but the amount you are borrowing and the total term over which you repay the mortgage will stay the same.
### How does a product switch work?
A product switch with us can be done online through our product switch portal. Click the "[View Mortgage](/portal/your-mortgage-payments)" option below to see your current payments. Then click "See Rates" to view new products that we may be able to offer you. Choose the product that suits you best and apply!
Since we already have all the information from when you took out your mortgage, we don’t require additional documentation. We also don’t need a new valuation of your property. This means that you can apply online same day providing you meet the eligibility criteria.
### How is this different to a remortgage?
The main difference between remortgaging and doing a product switch, is that if you remortgage you can select a product from across a variety of lenders, rather than from just us. However, if you do remortgage to another lender, be aware of any additional costs which may be incurred.
You will need to remortgage if you want to change more features of your mortgage, such as the loan amount or the repayment term of your mortgage, or if you want to borrow additional money.
        `,
  },
]

export const hopliteContentArticles = [
  {
    slug: "1",
    image:
      "https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/hoplite-files@2x.png",
    title: "Do I need to switch my mortgage product?",
    blurb:
      "All mortgage lenders have a Standard Variable Rate (SVR). We call this our mortgage variable rate.",
    readingTime: "3min",
    format: BlogPostFormat.MARKDOWN,
    type: ArticleType.LENDER,
    content: `
### What is the mortgage variable rate again?
The mortgage variable rate is the interest rate your product changes to at the end of the initial term (for example after 2 years, 3 years, 5 years, etc).
So on a 2-year fixed product, you would move on to the mortgage variable rate after 2-years.
The mortgage variable rate is also a variable rate which means it can go up or down over time. It is also typically higher than the introductory rate you were on during your initial product term.
### How do I avoid the mortgage variable rate?
You can avoid going on to our mortgage variable rate by moving to a new product when your initial term ends. You can move to a new product by doing a product switch with us on our online product switch portal (subject to the eligibility criteria) or remortgaging with a different lender.
You can see the products available to you as early as 6 months before your initial terms ends on our portal.
If you are at the end of your initial term, or are already on a mortgage variable rate, you could still switch to a new mortgage product (depending on your circumstances).
### Do I really need to consider switching?
Anyone who has a mortgage where the initial term is coming to an end, should explore alternative products available to ensure the monthly repayment remains within their budget.
People often move to a new product when their fixed, introductory tracker or discounted rate period ends. This initial period is usually 2-5 years after the mortgage starts.  If you do not move to a new product immediately, you can do so later (depending on your circumstances).
You don’t have to move to a new product, but most people do to save money by moving to a lower rate.
        `,
  },
  {
    slug: "2",
    image:
      "https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/hoplite-calendar@2x.png",
    title: "How soon can I get a new mortgage?",
    blurb:
      "As your mortgage approaches the end of its initial term you may choose to move to a new product (either with us or with another lender)",
    readingTime: "5min",
    format: BlogPostFormat.MARKDOWN,
    type: ArticleType.LENDER,
    content: `
As your mortgage approaches the end of its initial term you may choose to move to a new product (either with us or with another lender).
The process to move to a different lender (known as a remortgage) has five key stages and can take several weeks to complete. You can switch to a new product with us online via our product switch portal. A product switch with us does not need additional documentation, a valuation and the offer process can often happen on the same day.
See below for the stages required for a remortgage versus a product switch (with us), as well as how long a stage typically takes.
![Mortgage timeline](https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/article-2-Illustration@2x.png)
It’s also important that you factor in all the additional costs that remortgaging to another lender will incur.
To see your payments click "[View mortgage](/portal/your-mortgage-payments)" below.
_It’s important to note that a product switch is not an option when you are looking to borrow more money than your current mortgage amount. Learn more by reading “[What’s the difference between a product switch and a remortgage](/portal/learn/article/4)” or asking an independent financial advisor to talk you through your options._
### 1: Product selection
Your first step is to take a look at mortgage products available and determine which products best suit your needs. Clicking "[Switch now](/portal/switch-now)" at the bottom of this article will take you to our online product switch portal.
When you log on, we’ll instantly show you the products and rates we are able to offer you. You don’t have to proceed with a product switch to see products and rates.
If you want to see products and rates from other lenders an independent financial advisor can help.
### 2: Document collection
If you’re using a new lender they will require updated documentation – and each lender will have different requirements. Your independent financial advisor will be able to help you understand each lender’s specific requirements.
### 3: Property valuation
If you decide to borrow more or move to a new lender you may need a new property valuation. This valuation is undertaken to confirm a current market value of your property (this may or may not be at your cost).
### 4: Offer
The offer and ESIS (European Standard Information Sheet) is a formal document issued to you that details all aspects of the mortgage. Your advisor will take you through the document.
With our online portal, you will receive your Offer and ESIS instantly.
### 5: Completion
For a remortgage you will need a solicitor to help you complete the process. This includes conducting necessary property searches, repaying your mortgage with us and requesting funds from your new lender.
If you decide to switch with us, we don’t need additional information and you can formally accept the offer online.
Click "[View mortgage](/portal/your-mortgage-payments)" below to see your payments today and rates available.
_It’s important to note that a product switch is not an option when you are looking to borrow more money than your current mortgage amount. Learn more by reading “[What’s the difference between a product switch and a remortgage](/portal/learn/article/4)” or asking an independent financial advisor to talk you through your options._
        `,
  },
  {
    slug: "3",
    image:
      "https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/hoplite-compass@2x.png",
    title: "How do I select a new mortgage product?",
    blurb:
      "To pick the right mortgage product you must weigh up your personal circumstances against different products",
    readingTime: "8min",
    format: BlogPostFormat.MARKDOWN,
    type: ArticleType.LENDER,
    content: `
To pick the right mortgage product you must weigh up your personal circumstances against different products and their features to arrive at a solution that best suits your needs. You can also speak to an independent financial advisor for advice.
### Where do I start?
The first thing to decide is whether you want to do a product switch or a remortgage.
A product switch is offered by us. We let you switch (online) to a new mortgage product, where there are no changes to the loan amount and the repayment term matches the remaining term.
If you want to change mortgage product features such as the loan amount or repayment term, you will have to remortgage (learn more by reading “[What’s the difference between a product switch and a remortgage?](/portal/learn/article/4)”).
It is important to fully understand the impact of your product’s features on your situation today and your future goals. Read below for a refresher on the key mortgage features you need to know.
### What’s the ‘initial term’?
Whether you decide to product switch with us or remortgage away to another lender, you will need to pick the initial term.
The initial term is a set number of years that you commit to on your mortgage. This means that during this period you can’t change your mortgage (for example switching to another product or repaying your mortgage because you are selling your home), without incurring a fee, known as the early redemption charge (ERC).
Term lengths are commonly 2, 3 or 5 years – though some can be longer. It is important to think carefully about the term length, as exiting a product before the initial term is up can be costly and ERCs can be calculated as a percentage of the outstanding loan balance, depending on what the ERC is for that product.
Remember, the initial term is different to the repayment term (which is the number of years you’re committing to repay the mortgage in full).
For a product switch, the repayment type and term for your new product will be linked to your existing mortgage with us.
For example, let’s say your current mortgage is:
1. a 5-year fixed term,
2. and the repayment term was 30 years.
Then at the end of the 5-year initial term, you could do a product switch to a mortgage with a 25-year repayment term (30 years minus 5 years).
### I have a fixed rate, what does this mean?
Mortgage products generally have two types of interest rates: fixed rate products and variable rate products.
Fixed rate deals, as the name suggests, let you ‘fix’ or lock-in an interest rate up front that doesn’t change during the initial term – typically 2 or 5 years (as we talked about above). The main advantage of a fixed rate mortgage is that you know your monthly repayments will not change during the initial term even if interest rates go up, giving you budget certainty.
By contrast, variable mortgages, often referred to as trackers or discount variable products, have an interest rate that can change over time. Typically, the interest rate is linked to an external interest rate (for example, the Bank of England rate).
As a result, with a variable rate you benefit if interest rates fall but face higher monthly repayments if they rise.
Remember, variable mortgages (trackers and discount variable) should not be confused with our mortgage variable rate, which is the rate you move to when your initial term ends.
The key factors to bear in mind when deciding whether to opt for a fixed or variable rate are whether you think interest rates will rise or fall during your mortgage term, and how much flexibility you have in your budget to accommodate higher mortgage repayments. It’s important to seek advice from an independent financial advisor if you are unsure as to which option to take.
### Is that it, or do I need to consider anything else?
Each lender and every product they offer is different so there are many options which you need to consider. If you decide to remortgage or do a product switch, you need to choose the product which is right for you.
Some helpful tips to remember:
1. There are a myriad of mortgage products out there, so you can seek advice from an independent financial advisor to help guide you in this process.
2. Whilst rate is important, you also need to understand all the other associated costs with a mortgage. For example, a product switch with us will not incur any valuation or conveyancing costs but you will be committing to an initial term. If you commit to an initial term and then want to move house or change products before the initial term is up, this might mean an early repayment charge may be incurred. You may also want to look at whether products allow you to make overpayments without incurring penalties, enabling you to pay down your mortgage quicker and so pay less interest overall.
Remember: When deciding whether to do a product switch or remortgage, it’s important to factor in all the benefits and costs of a product. If you’re not sure what you may need or your circumstances have changed, make sure you speak to an independent financial advisor.
`,
  },
  {
    slug: "4",
    image:
      "https://eligible-production.s3.eu-west-2.amazonaws.com/content/images/hoplite-options@2x.png",
    title: "What’s the difference between a product switch and a remortgage?",
    blurb: "The initial term on your mortgage product with us is coming to an end",
    readingTime: "2min",
    format: BlogPostFormat.MARKDOWN,
    type: ArticleType.LENDER,
    content: `
The initial term on your mortgage product with us is coming to an end. To avoid going on to our mortgage variable rate (typically higher than your initial rate and the rate you will automatically revert to if you do nothing), you will either need to switch to a new product with us, known as a product switch, or you can remortgage with a different lender.
### What’s a product switch?
A product switch is when you move from your existing mortgage product to a new one. This option is available when you come to the end of your initial term. A product switch means you move to a new interest rate, but the amount you are borrowing and the total term over which you repay the mortgage will stay the same.
### How does a product switch work?
A product switch with us can be done online through our product switch portal. Click the "[View Mortgage](/portal/your-mortgage-payments)" option below to see your current payments. Then click "See Rates" to view new products that we may be able to offer you. Choose the product that suits you best and apply!
Since we already have all the information from when you took out your mortgage, we don’t require additional documentation. We also don’t need a new valuation of your property. This means that you can apply online same day providing you meet the eligibility criteria.
### How is this different to a remortgage?
The main difference between remortgaging and doing a product switch, is that if you remortgage you can select a product from across a variety of lenders, rather than from just us. However, if you do remortgage to another lender, be aware of any additional costs which may be incurred.
You will need to remortgage if you want to change more features of your mortgage, such as the loan amount or the repayment term of your mortgage, or if you want to borrow additional money.
        `,
  },
]
