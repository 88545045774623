import { SVR_ALERT_TYPES } from "../../../components/consumer/Conversations/PortfolioPage/svrAlertTypes"
import { currencyWholePounds, percent } from "../../../lib/formatters"
import { useGetWhatNextQuery } from "../../../redux/services/conversations"
import useProperty from "../../useProperty"
import s from "./RemainingRatesTable.module.scss"

/* The table inside the Remaining Rates Modal for Multiple Mortgages.
 * Opens when clicking the Question Icon for "Interest rate" in the SVRComparison component. */
export default function RemainingRatesTable() {
  const property = useProperty()
  const { data: whatNextData } = useGetWhatNextQuery(undefined, { skip: !property })

  if (!property || !whatNextData) {
    return null
  }

  const conversations = whatNextData.results.find(
    (p) => p.propertyId === property?.id
  )?.conversations

  const svrIds = conversations?.find((alert) =>
    SVR_ALERT_TYPES.includes(alert.type)
  )?.mortgageIds

  const svrMortgages = property.mortgages.filter((mortgage) =>
    svrIds?.includes(mortgage.id)
  )

  return (
    <div className={s.table}>
      {svrMortgages.map(({ id, loanAmount, followOnRate }) => (
        <div key={id} className={s.row}>
          <span>{currencyWholePounds(loanAmount)}</span>
          <span>{percent(followOnRate / 100)}</span>
        </div>
      ))}
    </div>
  )
}
