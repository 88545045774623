import cx from "classnames"
import {
  forwardRef,
  ReactNode,
  FocusEventHandler,
  KeyboardEvent,
  MouseEvent,
} from "react"

import useTrackClick from "../../../hooks/trackClick"
import { handleEnterPressed } from "../../../lib/keyboardEvents"
import Loading from "../Loading"
import s from "./Button.module.scss"
import { ButtonFill, ButtonSize, ButtonStyle } from "./Button.types"

export type ButtonProps = {
  children: ReactNode
  className?: string
  disabled?: boolean
  fill?: ButtonFill
  fullWidth?: boolean
  icon?: ReactNode
  id?: string
  label?: string
  loading?: boolean
  onClick?: (e?: MouseEvent | KeyboardEvent) => void
  onEnter?: () => void
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
  onMouseEnter?: (e?: MouseEvent | KeyboardEvent) => void
  onMouseLeave?: (e?: MouseEvent | KeyboardEvent) => void
  reverseIconPosition?: boolean
  size?: ButtonSize
  style?: ButtonStyle
  trackingId?: string
  trackingLabel?: string
  type?: HTMLButtonElement["type"]
  topMargin?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      children,
      className = "",
      fullWidth,
      icon,
      label,
      disabled = false,
      loading = false,
      onClick,
      onEnter,
      onFocus,
      onBlur,
      onMouseEnter,
      onMouseLeave,
      style = ButtonStyle.SOLID,
      fill = ButtonFill.PRIMARY,
      size = "sizeDefault",
      type = "button",
      trackingId,
      reverseIconPosition,
      trackingLabel,
      topMargin,
    },
    ref
  ) => {
    const { trackClick } = useTrackClick()
    const handleClick = (e?: MouseEvent | KeyboardEvent): void => {
      if (trackingLabel && trackingId) {
        trackClick(`${trackingLabel} - ${trackingId}`)
      } else if (trackingId) {
        trackClick(trackingId)
      }
      onClick?.(e)
    }

    function handleDisabledEvent(e?: MouseEvent | KeyboardEvent) {
      e?.preventDefault()
    }

    return (
      <button
        ref={ref}
        className={cx(`${s.button}`, s[size], style, fill, {
          [className]: className,
          [s.full]: fullWidth,
          [s.topMargin]: topMargin,
        })}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={(e) => (disabled ? handleDisabledEvent : handleClick(e))}
        onKeyDown={
          disabled ? handleDisabledEvent : handleEnterPressed(onEnter || handleClick)
        }
        type={type}
        aria-label={label || trackingId}
        aria-disabled={disabled}
        id={id}
      >
        {loading ? (
          <>
            <div className={s.loading}>
              <Loading />
            </div>
            {/* The invisible version of the main content inside .noOpacity will keep the width the same for buttons without fullWidth styling */}
            <div className={s.visibilityHidden}>
              <MainButtonContent icon={icon} reverseIconPosition={reverseIconPosition}>
                {children}
              </MainButtonContent>
            </div>
          </>
        ) : (
          <MainButtonContent icon={icon} reverseIconPosition={reverseIconPosition}>
            {children}
          </MainButtonContent>
        )}
      </button>
    )
  }
)

function MainButtonContent({
  children,
  reverseIconPosition,
  icon,
}: {
  children: ReactNode
  reverseIconPosition?: boolean
  icon: ReactNode
}) {
  return (
    <>
      {children}
      <div
        className={cx(s.icon, {
          [s.reverse]: reverseIconPosition,
        })}
      >
        {icon}
      </div>
    </>
  )
}

Button.displayName = "Button"

export default Button
