import { lifecycleModule } from "../../components/consumer/Lifecycle/utils/lifecycleModules"
import { SwitchStartDatePreference } from "../../components/consumer/ProductSelection/ProductSelectionSummaryPage/SwitchStartDatePreference.types"
import {
  ProductOption,
  ProductOptionWithStrings,
} from "../../redux/services/productSelection/ProductSelection.types"
import { notInterestedCategories } from "../retain"
import { BareUser } from "./Consumer.types"

export const paymentTypes = {
  INTEREST_ONLY: "interest-only",
  REPAYMENT: "repayment",
  PART: "part-and-part",
  SPLIT: "split",
} as const

export type paymentTypes = (typeof paymentTypes)[keyof typeof paymentTypes]

export const paymentSchemes = {
  FIXED: "fixed",
  DISCOUNT: "discount",
  TRACKER: "tracker",
  NONE: "",
} as const

export type paymentSchemes = (typeof paymentSchemes)[keyof typeof paymentSchemes]

export const loanPurpose = {
  FURTHER_ADVANCE: "further-advance",
  ADDITIONAL_BORROWING: "additional-borrowing",
  PURCHASE: "purchase",
  REMORTGAGE: "remortgage",
  BRIDGING: "bridging",
  PORTING: "porting",
  PRODUCT_TRANSFER: "product-transfer",
  PRODUCT_TRANSFER_WITH_FURTHER_ADVANCE: "product-transfer-with-further-advance",
  FIRST_TIME_BUYER: "first-time-buyer",
} as const

export type loanPurpose = (typeof loanPurpose)[keyof typeof loanPurpose]

export const mortgageTypes = {
  BUY_TO_LET: "buy-to-let",
  HOLIDAY_BUY_TO_LET: "holiday-buy-to-let",
  LTD_COMPANY_BUY_TO_LET: "limited-company-buy-to-let",
  OWNER_OCCUPIED: "owner-occupied",
  RETIREMENT_INTEREST_ONLY: "retirement-interest-only",
  SELF_BUILD: "self-build",
} as const

export type mortgageTypes = (typeof mortgageTypes)[keyof typeof mortgageTypes]

export const mortgageState = {
  NOT_INTERESTED: "not-interested",
  UNKNOWN: "unknown",
  MISSED: "missed",
  LOST: "lost",
  SUBMITTED: "submitted",
} as const

export type mortgageState = (typeof mortgageState)[keyof typeof mortgageState]

type PropertyType = {
  id: string
  address: string
  value: number
  postcode?: string | null
  snoozed: boolean
}
export type Applicant = {
  id: string
  name: string
  receivesEmail: boolean
  visible?: boolean
}
export type Mortgage = {
  accountNumber?: string | null
  advisor: BareUser
  applicants: Applicant[]
  caseId?: string | null
  caseLink?: string | null
  consolidatedMortgages: Mortgage[]
  currentRate: number
  debtAfterPreferentialRate: number
  description: string
  emailEveryone?: boolean
  expiryDate: string
  followOnStartDate: string
  expired: boolean
  followOnRate: number
  givenCurrentBalance?: number | null
  id: string
  isSharedOwnership: boolean
  lender: {
    id: string
    logo?: string | null
    logoBgColor?: string | null
    logoBgContrastColor?: string | null
    name: string
  }
  lifecycleModuleFlags?: Partial<Record<lifecycleModule, boolean>>
  loanAmount: number
  loanPurpose?: loanPurpose
  loanToValueAfterPreferentialRate: number
  monthlyCurrentPayment: number
  monthlyFollowOnPayment: number
  monthlyPreferentialPayment: number
  monthsOfPreferentialRate: number
  monthsUntilEndOfFullTerm: number
  monthsUntilEndOfTermAfterPreferentialRate: number
  notInterestedCategory?: notInterestedCategories | null
  paymentType?: paymentTypes
  pctOwned: number
  pointOfContactId: string
  pointOfContactIsExplicit: boolean
  preferentialRate: number
  prop: PropertyType
  propertyValue: number
  scheme: paymentSchemes
  selectedProductOptions: ProductOption[]
  selectedSwitchDate?: string | null
  startDate: string
  state?: mortgageState | null
  switchStartDatePreference?: SwitchStartDatePreference | null
  switchState?:
    | "without-advice"
    | "internal-advice"
    | "external-advice"
    | "with-switch-support"
    | null
  term: number
  type: mortgageTypes
  viewAsConsumerId?: string
}
export type RawMortgage = Omit<
  Mortgage,
  | "expired"
  | "prop"
  | "primary"
  | "viewAsConsumerId"
  | "selectedProductOptions"
  | "consolidatedMortgages"
> & {
  prop: Omit<PropertyType, "address"> & { address?: string | null }
  selectedProductOptions?: ProductOptionWithStrings[]
  numberOfProperties?: number
  brokerCode?: string
}

export interface Property {
  id: string
  address: string
  postcode?: string | null
  mortgages: Mortgage[]
  value: number
  active?: boolean
  snoozed: boolean
}
