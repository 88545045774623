import type { LabelPaths } from "../../../hooks/blocks/util.types"

export const internalAdvicePageBlocks = {
  lender: {
    internalAdvice: {
      headerTitle: {
        type: "text",
        description: "Heading element for the /internal-advice/ page",
        default: null,
      },
      heroImage: {
        type: "image",
        description:
          "Hero image for the /internal-advice/ page. Fallback icon is a tick within a circle.",
        default: null,
      },
      introContent: {
        type: "markdown",
        description:
          "Copy displayed on the /internal-advice/ page as the intro content.",
        default: null,
      },
      urlButton: {
        labelContent: {
          type: "text",
          description: "URL button label for  /internal-advice/ page ",
          default: "Book appointment",
        },
      },
      codeHeaderTitle: {
        type: "text",
        description: "Code Heading element for the /internal-advice/ page",
        default: null,
      },
      codeHeaderMdxContent: {
        type: "mdx",
        description: "Code Heading MDX content for the /internal-advice/ page",
        default: null,
      },
    },
  },
} as const

export type InternalAdviceLabelPaths = LabelPaths<typeof internalAdvicePageBlocks>
