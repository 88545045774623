import { LabelPaths } from "../../../hooks/blocks/util.types"

export const currentSituationPageBlocks = {
  /* TODO: ENG-5860 Convert scheduleCall into 2 content blocks */
  scheduleCall: {
    type: "text",
    description: `The CTA button label for scheduling a call on the Current Situation page or Lifecycle pages - note the default will be "Book call" on Lifecycle CTA pages and "Call me back" on Lender Current Situation`,
    default: null, // default will be "Book call" (Lifecycle) or "Call me back" (Lender)
  },
  currentSituation: {
    allGood: {
      type: "text",
      description:
        "The CTA button label for Ignoring Alerts on a particular mortgage on the Current Situation page",
      default: "Ignore Alerts",
    },
    title: {
      type: "text",
      description: "The title that goes at the top of the Current Situation page",
      default: "Your current situation",
    },
    secondaryCta: {
      content: {
        type: "text",
        description:
          "The secondary CTA button label on the Current Situation page, it will only display if this and currentSituationSecondaryCtaUrl are both set",
        default: null,
      },
      image: {
        type: "image",
        description:
          "The icon that displays inside the secondary CTA button on the Current Situation page",
        default: null,
      },
      url: {
        type: "text",
        description:
          "The URL that the secondary CTA button Current Situation page will navigate the user to, it will only work if this and currentSituationSecondaryCta are both set",
        default: null,
      },
    },
    secondaryZeroMortgages: {
      content: {
        type: "text",
        description:
          "The CTA button that displays on the Current Situation page if user has no mortgages to action, it will only display if this and currentSituationSecondaryZeroMortgagesUrl are both set",
        default: null,
      },
      image: {
        type: "image",
        description:
          "The icon that displays inside the CTA button on the Current Situation page if user has no mortgages to action",
        default: null,
      },
      url: {
        type: "text",
        description:
          "The URL that the CTA button on the Current Situation page will navigate the user to if they have no mortgages to action, it will only work if this and currentSituationSecondaryZeroMortgages are both set",
        default: null,
      },
    },
    lenderPreamble: {
      expiredMortgage: {
        mdxContent: {
          type: "mdx",
          description:
            "The preamble text for an expired mortgage, beneath the 'Your current situation' heading near the top of the page",
          default: `The initial term on {props.pronounLowercase} mortgage ended {props.expiryDate}. {props.pronounCapitalized} monthly payment changed when this happened.`,
        },
      },
      activeMortgage: {
        mdxContent: {
          type: "mdx",
          description:
            "The preamble text for an active mortgage, beneath the 'Your current situation' heading near the top of the page",
          default: `The initial term on {props.pronounLowercase} mortgage ends {props.expiryDate}. {props.pronounCapitalized} monthly payment will change when this happens.`,
        },
      },
    },
  },
} as const

export type CurrentSituationPagePaths = LabelPaths<typeof currentSituationPageBlocks>
