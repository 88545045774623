import { isDevelopmentEnv } from "./isDevelopmentEnv"

// This function is not good enough to use in production. LOCAL DEV ONLY
const DEV_ONLY_generateUUID = () =>
  "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx".replace(/[x]/g, () => {
    const r = Math.floor(Math.random() * 16)
    return r.toString(16)
  })

/**
 * generateUUID can only use window.crypto.randomUUID() in a secure
 * environment. Locally we run under http but on staging & production
 * we enforce https so it will be available.
 */
export const generateUUID = () =>
  isDevelopmentEnv() ? DEV_ONLY_generateUUID() : window.crypto.randomUUID()
